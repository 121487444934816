.onboarding-thumb {
	button {
		background: white;
		margin: 20px;
		border-radius: 50%;
		width: 63px;
		height: 63px;

		svg {
			width: 32px;
			height: 32px;
		}
	}

	.red-thumb svg {
		fill: #ff997a;
		position: relative;
		top: 5px;
		left: -1px;
	}

	.green-thumb svg {
		fill: #6fcd8b;
		position: relative;
		top: 2px;
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		button {
      margin: 0px 20px;
		}
	}

	/** IPHONE 8 */
	@media only screen and (device-width: 375px) {
		button {
			margin: 10px 20px;
		}
	}
}
