.onboarding-credits {
	height: -webkit-fill-available;

	h1 {
		margin-bottom: 10px;
	}

	// .credits-selection {
		.selection-tips {
			width: 330px;
			margin: 0px auto 20px;
			text-align: center;

			span {
				font-family: "BalooDa2SemiBold";
				color: white;
				font-size: 1.25rem;
			}
		}
	// }
}