.capacity-details-modal {
	.MuiDialog-paperWidthSm {
		width: 100%;
		max-height: 100%;
		height: 100%;
		margin: 0px;

		.MuiDialogContent-root {
			padding: 0px;
			position: relative;

			.close {
				position: absolute;
				top: 10px;
				left: 10px;
			}

			h2 {
				text-align: center;
				font-size: 1.25rem;
				font-family: "BalooDa2Bold";
				margin: 35px auto 10px;
			}

			.container {
				width: 290px;
				margin: 0px auto;
			}
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		.MuiDialog-paperWidthSm {
			position: absolute;
			right: 0px;
			width: 550px;

			.modal-dialog-content {
				height: 80vh;
				min-height: 520px;
				justify-content: flex-start;

				.close {
					top: 20px;
					left: 18px;
					width: 15px;
					height: 25px;
				}

				.header {
					text-align: center;
					margin: 0px auto 25px;

					h2 {
						width: auto;
						font-size: 1.5rem;
					}
				}

				.container {
					width: 400px;
				}

				.submit {
					margin-top: 5px;
				}
			}
		}
	}
}
