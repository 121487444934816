.slider-input {
	.label {
		color: var(--fridaa-blue);
		font-size: 1.125rem;
		font-family: "BalooDa2SemiBold";
	}

	.MuiSlider-rail {
		height: 3px;
		color: var(--grey);
		border-radius: 2.5px;
	}

	.MuiSlider-track {
		border-radius: 2.5px;
		height: 3px;
		color: var(--grey);
	}

	.MuiSlider-thumb {
		color: var(--fridaa-blue);
	}

	MuiSlider-thumb.Mui-focusVisible,
	.MuiSlider-thumb:hover {
		box-shadow: 0px 0px 0px 4px rgb(63 81 181 / 16%);
	}

	.slider-input .MuiSlider-thumb {
		color: var(--fridaa-blue);
	}

	// .MuiSlider-valueLabel span is for rendering on Heroku
	.PrivateValueLabel-circle-4,
	.MuiSlider-valueLabel span {
		background: transparent;
	}

	// .MuiSlider-valueLabel span span is for rendering on Heroku
	.PrivateValueLabel-label-5,
	.MuiSlider-valueLabel span span {
		color: var(--fridaa-blue);
		font-family: "BalooDa2SemiBold";
		font-size: 0.875rem;
		position: relative;
		top: 12px;
		left: -14px;
	}

	.unit {
		float: right;
		margin-top: -20px;
		text-transform: uppercase;
		color: var(--grey);
		font-size: 0.813rem;
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		.label {
			font-size: 1.25rem;
			margin-bottom: 5px;
		}

		.MuiSlider-root {
			margin-top: 9px;
		}

		.MuiSlider-track {
			height: 3px;
			color: var(--fridaa-blue);
		}

		.MuiSlider-thumb {
			color: white;
			height: 20px;
			width: 20px;
			margin-top: -8px;
		}

		// .MuiSlider-valueLabel span span is for rendering on Heroku
		.PrivateValueLabel-label-5,
		.MuiSlider-valueLabel span span {
			font-size: 1rem;
			top: 14px;
			left: -9px;
		}

		.unit {
			margin-top: -15px;
			font-family: "BalooDa2SemiBold";
			text-transform: uppercase;
			color: var(--grey);
			font-size: 0.875rem;
			letter-spacing: 0.2px;
		}
	}
}
