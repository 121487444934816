.onboarding-navigator {
	// height: -webkit-fill-available;
	background: linear-gradient(180deg, #fcbe1e 0%, #ffd974 100%);
	overflow-y: scroll;
	z-index: 2;
	padding: 25px 25px 0px 25px;
	width: 100%;
	justify-content: center;

	h1 {
		font-size: 1.875rem;
		font-family: "BalooDa2Bold";
		font-size: 1.5rem;
		line-height: 32px;
		text-align: center;
		color: white;
		max-width: 320px;
		margin: 0px auto;
		letter-spacing: 0.2px;
	}

	.onboarding-incomes,
	.onboarding-credits,
	.onboarding-contribution {
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.input-cards {
		gap: 15px;

		.MuiDialogContent-root {
			padding: 0;
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		height: auto;

		.onboarding-incomes,
		.onboarding-credits,
		.onboarding-contribution {
			margin: auto;
			height: 70%;
			justify-content: space-evenly;
		}

		.onboarding-credits.select-credits {
			height: 60%;
			position: relative;
			top: -30px;
			margin: auto auto;
			justify-content: space-evenly;
		}

		.onboarding-contribution {
			height: 65%;
		}

		h1 {
			max-width: 450px;
			font-size: 2.125rem;
			line-height: 38px;
			margin-bottom: 0px;
		}
	}

	/** MOBILES **/
	@media screen and (max-width: 480px) {
		padding: 13px 10px;

		.input-cards {
			flex-direction: column;
		}
	}
}
