.modal-input-value-card {
	.MuiDialog-paperWidthSm {
		border-radius: 20px;
		background: transparent;
		box-shadow: none;

		.MuiDialogContent-root {

			&:first-child {
				padding: 0px;
			}

			.input-card {
				height: 140px;
				margin: 5px 0px 0px;

				.modal-input-confirm {
					color: (--fridaa-blue);
					font-family: 'BalooDa2SemiBold';
					font-size: 1.2rem;
					margin-top: -0.4rem;
				}

				&:first-child {
					margin-bottom: 25px;
				}
			}
		}
	}
}
