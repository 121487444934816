.onboarding-help-modal {
	.MuiDialog-paperWidthSm {
		height: 100vh;
		max-height: 100%;
		width: 100%;
		border-radius: 0px;
		margin: 0px;

		.close {
			position: absolute;
			top: 10px;
			right: 10px;

			svg {
				width: 30px;
				height: 30px;
			}
		}

		.content {
			height: -webkit-fill-available;
		}

		.icon {
			margin: 0px auto;
		}

		span {
			color: var(--fridaa-blue);
			line-height: 24px;
			letter-spacing: -0.2px;
		}

		h3 {
			color: var(--fridaa-blue);
			font-size: 1.625rem;
			line-height: 32px;
			text-align: center;
			width: 300px;
			margin: 5px auto 14px;
			letter-spacing: -1.3px;
		}

		.list {
			width: 310px;
			background: var(--light-grey);
			border-radius: 10px;
			margin: 0px auto;
			padding: 10px 0px;

			.list-title {
				margin-left: 1em;
				font-weight: bold;
			}

			ul {
				margin: 0px;
				margin-block-start: 0px;
				padding-inline-start: 30px;

				span {
					letter-spacing: -0.5px;

					&:first-child {
						font-weight: bold;
						margin-right: 2px;
					}
				}
			}
		}

		.question-answer {
			margin: 12px auto 0px;
			text-align: center;

			span {
				
				&:first-child {
					font-weight: 600;
					margin-bottom: 2px;
				}
				&:last-child {
					font-family: 'BalooDa2Medium';
					width: 90%;
					margin: auto;
				}
			}
		}

		.subtitle {
			line-height: 22px;
			text-align: center;
			color: var(--fridaa-blue);
			width: 300px;
			margin: 0px auto 12px;
			font-family: 'BalooDa2Medium';
		}

		.separator {
			background: var(--light-grey);
			margin: 10px auto;
			width: 52px;
			height: 2px;
			border-radius: 3px;
		}

		.feedback {
			margin-bottom: 25px;

			span {
				text-align: center;
				font-weight: bold;
			}
		}

		/** IPHONE 8 */
		@media only screen and (device-width: 375px) {
			.MuiDialogContent-root {
				padding: 10px 10px 0px;
			}

			h3 {
				width: 320px;
				margin: 0px auto 5px;
			}

			.icon {
				svg {
					width: 30px;
					height: 30px;
				}
			}

			.separator {
				margin: 10px auto;
			}

			.feedback {
				margin-bottom: 0px;
			}
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		.MuiDialog-container {
			position: relative;
			// left: calc((100vw - 25vw) / 2 - 25vw);

			.MuiDialog-paperWidthSm {
				height: 70vh;
				min-height: 650px;
				max-height: 100%;
				width: 90%;
				border-radius: 20px;

				.MuiDialogContent-root:first-child {
					padding: 25px;
				}

				.content {
					height: 100%;

					span {
						letter-spacing: 0px;
					}

					h3 {
						font-size: 1.75rem;
						width: 400px;
						margin: 5px auto 10px;
						letter-spacing: 0px;
					}

					.subtitle {
						font-size: 1.125rem;
						margin: 3px auto 15px;
					}

					.list {
						width: 380px;
						padding: 10px 10px 10px 3px;

						ul {
							span {
								letter-spacing: 0px;

								&:first-child {
									font-family: "BalooDa2SemiBold";
								}
								&:last-child {
									font-family: "BalooDa2Medium";
								}
							}
						}
					}

					.question-answer {
						width: 350px;
					}

					.onboarding-thumb button {
						margin: 20px 20px 0px;
					}
				}
			}
		}
	}
}
