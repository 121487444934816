

.phone-input-wrapper {
  display: flex;
  flex-direction: column;

  .phone-number-link-input {
    margin: 15px auto 5px;
    background: var(--light-grey);
    border-radius: 25px;
    width: 100%;

    input {
      width: 60%;
      padding: 8px 0px 8px 20px;
      font-size: 1.125rem;
      font-family: "BalooDa2SemiBold";
      letter-spacing: 0.5px;

      &::placeholder {
        font-size: 1rem;
        font-family: "BalooDa2SemiBold";
        color: #C1D4ED;
        letter-spacing: 0.4px;
      }
    }
    
    button {
      width: 35%;
      background: var(--fridaa-blue);
      border-radius: 25px;
      color: white;

      span {
        margin: auto 0px;
        font-family: "BalooDa2Bold";
        letter-spacing: 0.5px;
      }

      svg {
        width: 11px;
        height: 11px;
        transform: rotate(-90deg);
        margin: auto 0px auto 6px;
      }
    }
  }

  .error-message {
    color: red;
  }
}

@media screen and (min-width: 769px) {
  .phone-input-wrapper {
    display: flex;
    flex-direction: row;
  }
}