.onboarding-header-step-indicator {
  margin: auto 0px;
  
	.indicator {
		width: 6px;
		height: 4px;
		background: #ffffff;
		mix-blend-mode: normal;
		opacity: 0.5;
		border-radius: 2px;
    margin-right: 5px;

    &:last-child {
      margin-right: 0px;
    }
	}

  .indicator.current {
    width: 15px;
    opacity: 1;
  }
}
