.property-type-selector-modal {
	.MuiDialog-paperWidthSm {
		height: 100%;
		max-height: 100%;
		width: 100vw;
		border-radius: 0px;
		margin: 0px;
		position: relative;

		.close {
			position: absolute;
			top: 15px;
			left: 15px;
		}

		.dialog-header {
			position: relative;
			// margin-bottom: 10px;
			margin: 5px auto 25px;

			h2 {
				text-align: center;
				margin: 0px;
				// margin: 35px auto 10px;
				font-family: "BalooDa2Bold";
				color: var(--fridaa-blue);
				width: calc(100% - 24px - 20px);
				font-size: 1.25rem;
				letter-spacing: -0.15px;
			}
		}

		.submit {
			margin-top: 50px;

			button {
				border-radius: 30px;
				background: var(--fridaa-blue);
				color: white;
				margin: auto;
				padding: 10px 40px;

				span {
					position: relative;
					top: 1px;
					font-family: "BalooDa2SemiBold";
					font-size: 1rem;
					letter-spacing: 0.6px;
					text-transform: uppercase;
				}

				svg {
					width: 15px;
					height: 12px;
					margin: auto 0px auto 8px;
				}
			}
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		.MuiDialog-paperWidthSm {
			position: absolute;
			right: 0px;
			width: 510px;

			.content {
				height: 70vh;
				min-height: 520px;
				justify-content: center;

				.close {
					top: 20px;
					left: 18px;
					width: 15px;
					height: 25px;
				}

				.dialog-header {
					text-align: center;
					margin: 0px auto 30px;

					h2 {
						width: auto;
						font-size: 1.5rem;
						letter-spacing: 0px;
					}
				}

				.submit {
					margin-top: 5px;
				}
			}
		}
	}
}
