.financial-infos {
  h2 {
    margin: 0px 0px 30px 0px;
    color: var(--fridaa-blue);
  }
  
  .input-value-block {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
}