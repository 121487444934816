.loan-duration-selector-item {
  button {
    border-radius: 10px;
    background: transparent;
    width: 50px;
    height: 50px;

    span {
      color: var(--fridaa-blue);
      font-weight: 600;
      margin: 0px auto;
    }

    .value {
      font-size: 1rem;
      position: relative;
      top: 3px;
    }

    .year {
      font-size: 0.875rem;
      text-transform: uppercase;
      position: relative;
      bottom: 4px;
    }
  }
  button.selected {
    background: var(--light-grey);

    span {
      font-family: 'BalooDa2Medium';
    }
  }

  /** DESKTOP **/
  @media screen and (min-width: 769px) {
    button {
      width: 55px;
      height: 52px;

      .value {
        font-size: 1.125rem;
        font-family: 'BalooDa2Medium';
      }

      .year {
        bottom: 6px;
      }
    }
    button.selected {
      background: white;
    }
  }
  @media screen and (max-width: 480px) {
    button.selected {
      background-color: #fff1ca;
    }
  }
}
