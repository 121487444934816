#capacity {
	height: 100%;
	padding: 15px;

	h1 {
		margin-top: 0px;
		font-family: "BalooDa2Bold";
		font-size: 1.5rem;
		line-height: 30px;
		text-align: center;
		letter-spacing: 0.38;
	}

	.slider-input {
		margin-top: 20px;
	}

	.colored-result-card {
		margin: 20px 0px 25px;
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		background: var(--light-grey);
		padding: 0px;
		display: flex;
		align-items: center;

		.inputs-values-blocks {
			display: none;
		}

		.content {
			margin: auto;
			width: 90%;
			padding: 0px 0px 10%;
			max-width: 500px;

			h1 {
				font-size: 1.75rem;
				margin: 0px 0px 45px;
			}

			.slider-input {
				margin-top: 30px;
			}
		}

		.colored-result-card {
			width: 80%;
			margin: 25px auto 35px;
		}
	}
}
