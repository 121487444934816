.details-item {
	margin: 20px 0px;
	border-radius: 15px;

	.icon {
		border-radius: 50%;
		background: #97afce;
		width: 22px;
		height: 22px;
		margin: auto 10px auto 0px;
		position: relative;
		bottom: 1px;

		svg {
			position: relative;
			bottom: 1px;
			left: 4.5px;
		}
	}
	.icons {
		border-radius: 50%;
		// background: #97afce;
		width: 22px;
		height: 22px;
		margin: auto 10px auto 0px;
		position: relative;
		bottom: 1px;

		svg {
			position: relative;
			bottom: 1px;
			left: 4.5px;
		}
	}

	.title {
		font-family: "BalooDa2SemiBold";
		font-size: 1.125rem;
		line-height: 30px;
	}



	.easy-funding {
		color: white;
		background: var(--green);
		border-radius: 30px;
		padding: 1px 10px;
		font-family: "BalooDa2SemiBold";
		font-size: 0.587rem;
	}
	.hard-funding {
		color: white;
		background: var(--orange-red);
		border-radius: 30px;
		padding: 1px 10px;
		font-family: "BalooDa2SemiBold";
		font-size: 0.875rem;
	}

	.value {
		margin: auto 0px auto auto;
		text-align: right;
		font-size: 1.125rem;
		font-family: "BalooDa2Bold";
		line-height: 30px;
	}
	.subvalue {
		margin: auto 0px auto auto;
		text-align: right;
		font-size: 1.0rem;
		font-family: "BalooDa2Medium";
		line-height: 30px;
		width: max-content;
	}
	.align-right {
		text-align: right;
	}

	.content {
		margin-top: 2px;

		span {
			font-size: 0.938rem;
			line-height: 18px;
			font-family: "BalooDa2Medium";
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		margin: 30px 0px;

		.title,
		.value {
			font-size: 1.25rem;
		}

		.content {
			margin-top: 5px;
			
			span {
				font-size: 1rem;
			}
		}
	}
}
