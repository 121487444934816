.download-app-modal {
	.MuiDialog-paperWidthSm {
		width: 90%;
		max-height: 90%;
		height: 90%;
		margin: 0px;
		border-radius: 20px;

		.MuiDialogContent-root {
			padding: 30px;

			.close {
				cursor: pointer;
				position: absolute;
				right: 15px;
				top: 15px;
			}

			.header {
				margin-bottom: 25px;

				h2 {
					color: var(--fridaa-blue);
					font-size: 1.25rem;
					line-height: 28px;
					text-align: center;
					font-family: "BalooDa2Bold";
					margin: 15px auto 10px;
				}

				.icon svg {
					margin: 0px auto;
				}
			}

			.store-logo {
				margin: 0px auto;
			}

			.container {
				width: 290px;
				margin: 0px auto;
			}
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		.MuiDialog-paperWidthSm {
			max-width: 710px;
			max-height: 490px;

			.MuiDialogContent-root {
				padding: 0px;
			}
		}
	}
}
