.onboarding-next-button {
	// margin: 30px auto 20px;
	margin: 0 auto;

	button {
		margin: 0px auto;
		background: var(--fridaa-blue);
		color: white;
		border-radius: 30px;
		// padding: 9px 18px;
		width: 150px;
		height: 50px;

		span {
			font-family: "BalooDa2SemiBold";
			letter-spacing: 0.4px;
			text-transform: uppercase;
			font-size: 1rem;
			margin-right: 5px;
		}

		svg {
			width: 12px;
			height: 12px;
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		// margin: 0px auto 30px;
		margin: 0px auto 0px;

		button svg {
			position: relative;
			bottom: 1px;
		}
	}

	/** IPHONE 8 */
	@media only screen and (device-width: 375px) {
		margin: 15px auto;
	}
}
