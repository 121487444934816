#capacityFunding {
	padding: 30px;
	box-sizing: border-box;
	min-height: 100vh;
	display: flex;

	h1 {
		margin-top: 0px;
		font-family: "BalooDa2Bold";
		font-size: 1.5rem;
		line-height: 30px;
		text-align: center;
		letter-spacing: 0.38;
	}

	.slider-input {
		margin-top: 20px;
	}

	.colored-result-card {
		margin: 20px 0px 25px;
	}

	.tips-funding {
		border-radius: 20px 0px 20px 20px;
		background-color: var(--orange-red);
		padding: 15px;
		color: white;
		font-size: 12px;
	}

	.legal-text {
		color: #97AFCE;
		font-size: 12px;
	}
}

	/** DESKTOP **/
@media screen and (min-width: 769px) {
	#capacityFunding {
		background: var(--light-yellow);
		padding: 0px;

		.inputs-values-blocks {
			display: none;
		}

		.content {
			margin: auto;
			width: 90%;
			max-width: 500px;

			h1 {
				font-size: 1.75rem;
				margin: 0px 0px 45px;
			}

			.slider-input {
				margin-top: 30px;
			}
		}

		.colored-result-card-funding {
			width: 100%;
		}
	}
}
