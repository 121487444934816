.property-funding-type-selector {
  margin-top: 10px;
  background-color: white;
  padding: 20px;
  border-radius: 73.25px;

  .icon-selected {
    border-radius: 50%;
    background: white;
    width: 40px;
    height: 40px;
    overflow: hidden;
    svg {
      position: relative;
      width: 40px;
      height: 40px;
    }
  }
  .title {
    color: var(--grey);
    font-size: 0.75rem;
    font-weight: 600;
  }

  button {
    background: transparent;
    width: 100%;
    margin-top: -5px;

    .type-underline {
      font-family: "BalooDa2Bold";
      color: var(--fridaa-blue);
    //   font-size: 1.063rem;
	  text-decoration: underline;
    }
	.type-no-underline {
		font-family: "BalooDa2SemiBold";
		color: var(--fridaa-blue);
		margin-top: 10px;
    margin-left: 10px;
	  }

    .chevron-down {
      transform: rotate(-90deg);
      margin: auto 0px;
      fill: #c1d4ed;
    }
  }
}


  /** DESKTOP **/
@media screen and (min-width: 769px) {
  .property-funding-type-selector {
    margin-top: 20px;

    .title {
      font-size: 1rem; 
      margin-bottom: 6px;
    }

    button {
      .type {
        font-size: 1.25rem;
      }
    }

    svg {
      height: 8px;
      position: relative;
      top: 2px;
    }
  }
}

@media screen and (max-width: 768px) {
  .property-funding-type-selector {
    background-color: #FFF1CA;
    padding: 10px;
    margin-top: 20px;

    button {
      font-size: 0.8rem;
    }

    .type-no-underline {
      text-align: left;
      line-height: 16px;
    }
  }
}