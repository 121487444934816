.property-type-selector {
	margin-top: 10px;
	border-bottom: 2px solid #c1d4ed;

	.title {
		color: var(--grey);
		font-size: 0.75rem;
		font-weight: 600;
	}

	button {
		background: transparent;
		width: 100%;
		margin-top: -5px;

		.type {
			font-family: "BalooDa2SemiBold";
			color: var(--fridaa-blue);
			font-size: 1.063rem;
		}

		svg {
			transform: rotate(-90deg);
			margin: auto 0px;
			fill: #c1d4ed;
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		margin-top: 20px;

		.title {
			font-size: 1rem;
			margin-bottom: 6px;
		}

		button {
			.type {
				font-size: 1.25rem;
			}
		}

		svg {
			height: 8px;
			position: relative;
			top: 2px;
		}
	}
}
