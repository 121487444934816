.input-card {
	background: white;
	border-radius: 20px;
	// transform: rotate(-3.07deg);
	// width: 60%;
	padding: 10px 10px 0px 10px;
	width: 230px;
	height: 140px;
	margin: 15px auto 10px;

	.header {
		position: relative;

		.label {
			color: var(--grey);
			width: fit-content;
			border-radius: 20px;
			padding: 0px 6px;
			height: 19px;

			span {
				font-size: 0.813rem;
				font-family: "BalooDa2SemiBold";
				position: relative;
				top: -4px;
			}
		}

		button {
			position: absolute;
			right: 5px;
			padding: 0px;
		}
	}

	.icon {
		margin: 5px auto 0px;
		height: 41px;

		svg {
			width: 41px;
			height: 41px;
			margin: 0px auto;
		}
	}

	.value {
		width: 180px;
		margin: 5px auto 0px;
		border-bottom: 2px dashed var(--grey);
		line-height: 36px;

		input,
		span {
			line-height: 36px;
		}

		input {
			color: var(--fridaa-blue);
			font-size: 1.5rem;
			font-family: "BalooDa2Bold";
			width: 110px;
			height: auto;
			text-align: right;
			padding: 0px;
		}

		.units {
			margin: auto 0px auto 3px;

			span {
				color: var(--fridaa-blue);
				font-family: "BalooDa2Bold";
				font-size: 1rem;

				&:first-child {
					font-size: 1.5rem;
					margin-right: 2px;
				}
			}
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		.value {
			width: 180px;

			input {
				font-size: 1.75rem;
			}

			.units {
				span {
					&:first-child {
						font-size: 1.75rem;
					}
				}
			}
		}
	}

	/** MOBILES **/
	@media screen and (max-width: 480px) {
		.value {
			width: 180px;
			
			input {
				width: 120px;
			}
		}
	}

	/** IPHONE 8 */
	@media only screen and (device-width: 375px) {
		height: 110px;

		.icon {
			margin: 0px auto;
			height: 38px;

			svg {
				width: 38px;
				height: 38px;
			}
		}
	}
}
