.download-app-modal-desktop {
	height: 100%;
  position: relative;

  .close {
    svg {
    width: 30px;
    height: 30px;
    fill: var(--fridaa-blue);
  }
}

	.left-column {
    padding: 40px 0;
		width: 100%;

    h2 {
      font-family: "BalooDa2Bold";
      width: 285px;
      margin: 0px auto;
      text-align: center;
      line-height: 30px;
      color: var(--fridaa-blue);
    }

    .logo {
      margin: 18px auto 15px;
      width: fit-content;
    }

    .link-sentence {
      text-align: center;
      font-size: 1.125rem;
      margin-top: 15px;

      span {
        font-family: 'BalooDa2Medium';
      }
    }
  
	}

	.right-column {
		width: 40%;
		height: auto;
		background: var(--fridaa-blue);
    display: none;
	}
  @media screen and (min-width: 769px) {
    .close {
      svg {
      width: 30px;
      height: 30px;
      fill: white;
      }
    }

    .right-column {
      width: 40%;
      padding-top: 130px;
		height: auto;
		background: var(--fridaa-blue);
      display: block;
    }
    .left-column {
      padding: 40px 20px;
      width: 100%;
  
      h2 {
        font-family: "BalooDa2Bold";
        width: 285px;
        margin: 0px auto;
        text-align: center;
        line-height: 30px;
        color: var(--fridaa-blue);
      }
  
      .logo {
        margin: 18px auto 15px;
        width: fit-content;
      }
  
      .link-sentence {
        text-align: center;
        font-size: 1.125rem;
  
        span {
          font-family: 'BalooDa2Medium';
        }
      }
    }
	}
}
