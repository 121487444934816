.discover-funding-passeport-button {
	background-color: var(--green);
	border-radius: 30px;
  border-width: 1px;
  height: 50px;
  padding: 5px 20px 5px 20px;
  margin: 2px auto;

	span {
    color: white;
		line-height: 22px;
		letter-spacing: 0.2px;
    font-family: "BalooDa2SemiBold";
    width: fit-content;
	}
  @media screen and (min-width: 769px) {
    margin-top: 40px;
  }

}
