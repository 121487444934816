.sidebar-illu {
  .ellipse {
		// border-radius: 50%;
    // background: #fff1ca;
    position: fixed;
    bottom: -15vw;
    left: -3.5vw;
    width: 32vw;
    height: 16vw;
	}

	.illu svg {
		position: fixed;
    width: 30vw;
    height: 16vw;
    z-index: 2;
    bottom: -5px;
    left: -30px;
	}
}