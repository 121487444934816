.details-tab {
	.container {
		background: var(--light-grey);
		border-radius: 28px;

		button {
			width: 50%;
			border-radius: 28px;
      		padding: 8px 20px;

			span {
				font-family: "BalooDa2SemiBold";
				line-height: 22px;
				text-align: center;
				letter-spacing: 0.4px;
				color: #c1d4ed;
			}
		}

		.mensual {
			background: var(--fridaa-blue);

			span {
				color: white;
			}
		}
		.status {
			background: var(--fridaa-blue);

			span {
				color: white;
			}
		}
		.status {
			background: var(--grey);

			span {
				color: white;
			}
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		margin-bottom: 35px;
	}
}
