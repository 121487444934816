.download-app-carousel {
	
	margin-top: 10px;

	img {
		width: 180px;
		height: 310px;
	}

	.BrainhubCarousel {
		margin-left: 0px;

		// li {
		//   // &:first-child {
		//   //   position: relative;
		//   //   left: -10px;
		//   // }
		// 	//   max-width: 200px!important;
		// 	//   min-width: 200px!important;
		// 	// margin: 0px 20px!important;
		// }

		.BrainhubCarousel__arrows {
			background-color: white;

			&:hover {
				background-color: white;

				span {
					border-color: #c1d4ed;
				}
			}

			&:first-child {
				position: relative;
				left: -5px;
			}

			span {
				padding: 6px;
				border-color: #c1d4ed;
			}
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		img {
			border-radius: 15px;
		}

		.BrainhubCarousel {
			.BrainhubCarousel__arrows {
				background-color: transparent;

				&:hover {
					background-color: transparent;
				}

				&:first-child {
					position: relative;
					left: 6px;
				}
				&:last-child {
					position: relative;
					left: -6px;
				}

				span {
					padding: 6px;
					border-color: white;
				}
			}
		}
	}

	/** MOBILES **/
	@media screen and (max-width: 480px) {
		img {
			border-radius: 20px;
		}
	}
}
