@font-face {
  font-family: 'BalooDa2Bold';
  src: local('BalooDa2-Bold'), url(./fonts/Baloo_Da_2/BalooDa2-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'BalooDa2Medium';
  src: local('BalooDa2-Medium'), url(./fonts/Baloo_Da_2/BalooDa2-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'BalooDa2';
  src: local('BalooDa2-Regular'), url(./fonts/Baloo_Da_2/BalooDa2-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'BalooDa2SemiBold';
  src: local('BalooDa2-SemiBold'), url(./fonts/Baloo_Da_2/BalooDa2-SemiBold.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'BalooDa2', 'BalooDa2Medium', 'BalooDa2Bold', 'BalooDa2SemiBold',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --light-grey: #E9F2FF;
  --grey: #97AFCE;
  --fridaa-blue: #1E1548;
  --fridaa-orange: #FCBF1E;
  --input-label: #97afce;
  --light-yellow: #FFF1CA;
  --red: #FF0000;
  --dark-yellow: #FFDE86;
  --green: #49B569;
  --orange-red: #FF7A50;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}
.column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.content-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

input {
  background: transparent;
  border: 0px solid transparent;

  &:focus {
    outline: 0px solid transparent;
  }
}

button {
  background: transparent;
  font-family: 'BalooDa2';
  cursor: pointer;
  border: 0px solid transparent;
  font-family: 'BalooDa2';
  font-size: 1rem;
  padding: 0px;
}

h1 {
  font-family: 'BalooDa2SemiBold';
  color: var(--fridaa-blue);
}
h3 {
  font-family: 'BalooDa2';
}
