.passport-modal {
	
	.MuiDialog-paperWidthSm {
		width: 100%;
		max-height: 100%;
		height: 100%;
		margin: 0px;

		.MuiDialogContent-root {
			padding: 0px;
			position: relative;
			.top {
				margin-top: 20px;
				display: flex;
				flex-direction: column-reverse;
				align-items: center;
			}
			svg {
				
				margin-top: 25px;
			}

			.close {
				position: absolute;
				top: 0px;
				right: 10px;
			}

			h1 {
				text-align: center;
				font-size: 1.25rem;
				font-family: "BalooDa2Bold";
				margin: 20px auto;
			}

			.container {
				width: 290px;
				margin: 0px auto;
			}
			.errorMessage {
				color: var(--red);
				font-size: 0.813rem;
				font-family: "BalooDa2SemiBold";
				margin: 0px auto;
				text-align: center;				
			}
		}
	}
}


/** DESKTOP **/
@media screen and (min-width: 769px) {
	.passport-modal {
		.MuiDialog-paperWidthSm {
			position: absolute;
			right: 0px;
			width: 550px;
			
			.MuiDialogContent-root {
				padding: 0px;
				position: relative;

				.top {
					margin-top: 20px;
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					margin-bottom: 60px;
				}

				h1 {
					margin: 0;
					font-size: 30px;
					width: 240px;
					text-align: left;
				}
			}

			.modal-dialog-content {
				justify-content: center;

				.close {
					top: 20px;
					left: 18px;
					width: 15px;
					height: 25px;
				}

				// .top {
				// 	text-align: left;
				// 	margin: 0px auto 2px;

				// 	h1 {
				// 		width: auto;
				// 		font-size: 1.5rem;
				// 	}
				// }

				.container {
					width: 400px;
				}

				.submit {
					margin-top: 5px;
				}
			}
		}
	}
}