.onboarding-funding-header {
	margin-bottom: 7px;

	.go-back {
		height: 27px;
	}

	.leave {
		color: white;
		font-size: 1rem;
		line-height: 27px;
		letter-spacing: 0.2px;
		color: #ffffff;
	}
	.mendatory-warning{
		font-size: 1rem;
		line-height: 20px;
		letter-spacing: 0.2px;
		background-color: #FF764D;
		position: absolute;
		right: 2%;
		border-radius: 48px;
		padding-left: 3px;
		padding-right: 3px;
		// height: 20px;
		span {
			font-size: 11px;
			color: #ffffff;
			line-height: 19px;
			margin-right: 5px;
			margin-top: 2.5px;
		}
		svg {
			width: 12px;
			height: 12px;
			margin-left: 5px;
			margin-right: 5px;
			// padding-top: 5px;
		}
	}
}
