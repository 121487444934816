

.phone-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .yellow-bg-button {
    margin-top: 20px;   
    margin-bottom: 20px;
    width: 50px;
    height: 50px;
    background-color: #FCBF1E;
    border-radius: 50px;

    svg {
      width: 11px;
      height: 11px;
      margin: auto 0px auto 0px;
    }
  }

  .phone-number-link-input-custom {
    margin: 15px auto 5px;
    // background: var(--light-grey);
    border-bottom: 2px solid var(--light-grey);
    width: 100%;

    input {
      width: 100%;
      padding: 8px 0px 8px 20px;
      font-size: 1.125rem;
      font-family: "BalooDa2SemiBold";
      letter-spacing: 0.5px;

      &::placeholder {
        font-size: 1rem;
        font-family: "BalooDa2SemiBold";
        color: #C1D4ED;
        letter-spacing: 0.4px;
      }
    }
    
    
  }

  .error-message {
    color: red;
  }
}

@media screen and (min-width: 768px) {
  .phone-number-link-input-custom {
    margin: 15px auto 5px;
    // background: var(--light-grey);
    border-bottom: 2px solid var(--light-grey);
    width: 380px;

    input {
      width: 60%;
      padding: 8px 0px 8px 20px;
      font-size: 1.125rem;
      font-family: "BalooDa2SemiBold";
      letter-spacing: 0.5px;

      &::placeholder {
        font-size: 1rem;
        font-family: "BalooDa2SemiBold";
        color: #C1D4ED;
        letter-spacing: 0.4px;
      }
    }
  }
}