a.coaching-link {
    margin: 10px 0px;
    text-decoration: none;
    color: var(--fridaa-blue);
}

.coaching-link-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: linear-gradient(30deg, #8EDE91, #CCF99B);
    border-radius: 15px;
    padding: 10px 4px;

    img.coaches {
        width: 120px;
        height: 82px;
        margin-left: -6px;
    }

    .coaching-text-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 8px;

        .coaching-text {
            font-size: 14px;
            text-decoration: none;
        }

        .coaching-text-bold {
            font-weight: bold;
        }
    }

    .discover-button {
        background: white;
        max-width: fit-content;
        border-radius: 30px;
        padding: 1px 10px;
        margin-top: 10px;
        font-family: "BalooDa2SemiBold";
        font-size: 0.875rem;
        svg {
            position: relative;
            bottom: 1px;
            margin: auto 0px auto 5px;
        }
    }

    & > svg {
        position: relative;
        bottom: 1px;
        margin: auto 0px auto 5px;
        height: 20px;
        width: 20px;
        margin-right: 10px;
    }
}