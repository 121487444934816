.sidebar-capacity {
  width: 85%;
  margin: auto;
  padding: 15% 0px 0px;
  max-width: 300px;
  min-height: 400px;

  .logo img {
    width: 200px;
    // height: 25px;
  }
}