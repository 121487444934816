.onboarding-infos-button {
	margin: 10px auto 20px;

	button {
		margin: 0px auto;
		border: 1px solid white;
		color: white;
		border-radius: 30px;
		// padding: 9px 18px;
		width: 220px;
		height: 50px;

		span {
			font-family: "BalooDa2SemiBold";
			letter-spacing: 0.4px;
			text-transform: uppercase;
			font-size: 1rem;
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		margin: 0px auto;
	}

	/** IPHONE 8 */
	@media only screen and (device-width: 375px) {
		margin: 0px auto;
	}
}
