.loan-duration-selector {
	margin-top: 20px;

	.title {
		color: var(--fridaa-blue);
		font-size: 1.125rem;
		font-family: "BalooDa2SemiBold";
	}

	.items {
		margin-top: 5px;
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		.title {
			font-size: 1.25rem;
		}

		.items {
			margin-top: 10px;
		}
	}
}
