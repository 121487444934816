.input-value-block {
	display: flex;
	flex-direction: column;
	position: relative;
	// width: 78px;
	// height: 78px;
	width: 100px;
	height: 90px;
	padding: 10px;
	background: var(--light-grey);
	border-radius: 15px;

	.icons {
		height: 25px;
		margin-bottom: 2px;

		.icon svg {
			width: 25px;
			height: 25px;
		}
	}

	.label {
		font-family: "BalooDa2Bold";
		color: var(--input-label);
		font-size: 0.75rem;
		line-height: 20px;
		text-transform: uppercase;
		margin: 3px 0px 2px;
		text-align: left;
	}

	.value {
		color: var(--fridaa-blue);
		// font-weight: 600;
		font-size: 1.125rem;
		// padding: 0px;

		span {
			font-family: "BalooDa2SemiBold";
			line-height: 15px;

			&:last-child {
				margin-left: 2px;
			}
		}
	}

	.currency {
		font-weight: 600;
		font-size: 1.125rem;
		height: 20px;
		position: relative;
		top: -0.5px;
		color: var(--fridaa-blue);
	}

	.dash {
		width: 100%;
		border-bottom: 1.5px dashed;
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		width: auto;
		height: auto;
		flex-direction: row;
		align-items: center;
		padding: 7px 10px 12px;

		.icons {
			height: 30px;
			.icon {
				margin: 6px 12px 0px 0px;

				svg {
					width: 30px;
					height: 30px;
				}
			}
			.edit {
				position: absolute;
				right: 12px;
				top: 5px;
			}
		}

		.label {
			font-size: 0.875rem;
			line-height: 20px;
			text-transform: uppercase;
			margin: 0px 0px 0px 0px;
		}

		.value {
			padding-right: 15px;
			border-bottom: thin dashed #97afce;

			input {
				height: fit-content;
			}

			.currency {
				top: 0px;
				height: 21px;
				display: flex;
				align-items: center;
			}
		}
	}
}


/** MOBILES **/
@media screen and (max-width: 480px) {
	.input-value-block {

		.column {
			width: 100%;
		}

		.icons {
			width: 100%;
		}

		.label {
			margin: 0px 0px -3px;
		}

		.value {
			display: -webkit-box;
			-webkit-box-orient: horizontal;
			border-bottom: thin dashed #97afce;
		}
	}
}