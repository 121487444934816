.colored-result-card-funding {
	border-radius: 20px;
	padding: 12px 9px;
	margin-top: 30px;
	background-color: #e9f2ff;

	.details-result-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;
		justify-content: space-between;
	}

	.details-right-wrapper {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		height: 100%;
		justify-content: space-between;
	}

	.container {
		width: 100%;
		border: 1.5px dashed #FCBF1E;
		border-radius: 14px;
		padding: 10px 18px;
		color: var(--fridaa-blue);

		.title {
			text-align: left;
			font-family: "BalooDa2SemiBold";
		}
		.subtitle {
			font-family: "BalooDa2SemiBold";
			font-size: 1.0rem;
			text-align: left;
			// margin-left: -10px;
			margin-top: -10px;
			margin-bottom: 10px;
			color: #97AFCE;
		}
		.value-funding {
			text-align: left;
			font-family: "BalooDa2Bold";
			font-size: 2.5rem;
			line-height: 40px;
			// margin-top: -5px;
		}
		.billing {
			font-family: "BalooDa2SemiBold";
			font-size: 1.0rem;
			line-height: 20px;
			margin-top: -10px;
			margin-bottom: 10px;
			margin-left: 10px;
		}

		.details-funding {
			background: var(--dark-yellow);
			max-width: fit-content;
			border-radius: 30px;
			padding: 1px 10px;
			// margin-left: 70px;
			// margin-top: 10px;
			font-family: "BalooDa2SemiBold";
			font-size: 0.875rem;
			svg {
				position: relative;
				bottom: 1px;
				margin: auto 0px auto 5px;
			}
		}
		.easy-funding {
			// margin-left: 34%;
			// max-width: fit-content;
			color: white;
			margin-top: 50px;
			background: var(--green);
			border-radius: 30px;
			padding: 1px 10px;
			font-family: "BalooDa2SemiBold";
			font-size: 0.587rem;
		}
		.hard-funding {
			// margin-left: 34%;
			// max-width: fit-content;
			color: white;
			margin-top: 50px;
			background: var(--orange-red);
			border-radius: 30px;
			padding: 1px 10px;
			font-family: "BalooDa2SemiBold";
			font-size: 0.587rem;
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		padding: 8px;
		box-sizing: border-box;
		background-color: white;
		
		.container {
			.title {
				font-size: 1.125rem;
			}

			.value {
				font-size: 1.75rem;
			}

			.details {
				position: relative;
				top: 3px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		background-color: #fff1ca;
	}
	
}
