.download-app-modal-desktop {
	height: 100%;
  position: relative;

  .close {
    svg {
    width: 30px;
    height: 30px;
    fill: white;
  }
}

	.left-column {
    padding: 40px 20px;
		width: calc(60% - 40px);

    h2 {
      font-family: "BalooDa2Bold";
      width: 285px;
      margin: 0px auto;
      text-align: center;
      line-height: 30px;
      color: var(--fridaa-blue);
    }

    .logo {
      margin: 18px auto 15px;
      width: fit-content;
    }

    .link-sentence {
      text-align: center;
      font-size: 1.125rem;

      span {
        font-family: 'BalooDa2Medium';
      }
    }
	}

	.right-column {
		width: 40%;
		height: auto;
		background: var(--fridaa-blue);
	}
}
