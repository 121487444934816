.colored-result-card {
	border-radius: 20px;
	padding: 12px 9px;

	.container {
		width: 100%;
		border: 1.5px dashed #c1d4ed;
		border-radius: 14px;
		padding: 10px 18px;
		color: var(--fridaa-blue);

		.title {
			font-family: "BalooDa2SemiBold";
		}

		.value {
			text-align: left;
			font-family: "BalooDa2Bold";
			font-size: 1.5rem;
			line-height: 40px;
			margin-top: -5px;
		}

		.details {
			background: #c1d4ed;
			border-radius: 30px;
			padding: 1px 10px;
			font-family: "BalooDa2SemiBold";
			font-size: 0.875rem;

			svg {
				position: relative;
				bottom: 1px;
				margin: auto 0px auto 5px;
			}
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		padding: 8px;
		background-color: white;
		
		.container {
			.title {
				font-size: 1.125rem;
			}

			.value {
				font-size: 1.75rem;
			}

			.details {
				position: relative;
				top: 3px;
			}
		}
	}
}
