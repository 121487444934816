.scan-qr-code {
  text-align: center;

  span {
    font-family: 'BalooDa2Medium';
  }

  .qr-code {
    margin: 15px auto 0px;
    width: 120px;
    height: 120px;
  }
}