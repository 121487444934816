.onboarding-header {
	margin-bottom: 7px;

	.go-back {
		height: 27px;
	}

	.leave {
		color: white;
		font-size: 1rem;
		line-height: 27px;
		letter-spacing: 0.2px;
		color: #ffffff;
	}
}
