.sidebar-onboarding {
	.content {
		height: calc(100vh - 200px);
		width: fit-content;
		max-width: 300px;
		margin: 0px auto;

		.content-header {
			margin: 0px auto;

			h2 {
				font-size: 2.625rem;
				line-height: 49px;
				margin: 0px 0px 5px;
				position: relative;
				left: -3px;
				color: #1E1548;
			}

			.logo {
				// width: 125.46px;
				height: 50px;
				margin: 5px 0px 0px;
			}
		}

		.intro {
			width: 80%;
			margin: 50px 0px 0px 5px;

			p {
				font-size: 1.125rem;
				line-height: 26px;
				margin: 0px;
				color: var(--fridaa-blue);

				span {
					font-family: "BalooDa2Bold";
				}
			}
		}
	}

	@media screen and (max-width: 1150px) {
		.content {
			.content-header {
				max-width: 200px;
			}
			.intro {
				margin: 50px 0px 0px 10px;
				max-width: 220px;
			}
		}
	}
}
