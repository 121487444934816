.capacity-funding-passport-input {
	background: white;
	border-radius: 20px;
	padding: 0px 40px;
	box-sizing: border-box;
	width: 100%;
	height: auto;
	margin: 0px 0px 60px 0px;

	.headers-label {
		// position: relative;
		// margin-left: 35px;

		.label {
			color: var(--grey);
			// width: fit-content;
			border-radius: 20px;
			// padding: 0px 6px;
			height: 19px;

			span {
				font-size: 0.813rem;
				font-family: "BalooDa2SemiBold";
				position: relative;
				top: -4px;
			}
		}
	}

	.value-passport {
		width: 100%;
		margin: 5px auto 0px;
		border-bottom: 1px solid var(--grey);
		line-height: 36px;
		
		input,
		span {
			line-height: 36px;
		}

		input {
			color: var(--fridaa-blue);
			font-size: 1.5rem;
			font-family: "BalooDa2Bold";
			width: 100%;
			height: auto;
		}

		
	}
	.isError {
		width: 80%;
		margin: 5px auto 0px;
		border-bottom: 1px solid var(--red);
		line-height: 36px;
		
		input,
		span {
			line-height: 36px;
		}

		input {
			color: var(--fridaa-blue);
			font-size: 1.5rem;
			font-family: "BalooDa2Bold";
			width: 100%;
			height: auto;
		}

		
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		.isError {
			width: 100%;

			input {
				font-size: 1.75rem;
			}

		}
		.value-passport {
			width: 100%;

			input {
				font-size: 1.75rem;
			}

		}
	}

	/** MOBILES **/
	@media screen and (max-width: 768px) {
		margin: 0px 0px 20px 0px;
		.value-passport {
			width: 100%;
			
			input {
				width: 100%;
				line-height: 28px;
			}
		}
		.isError {
			width: 100%;
			
			input {
				width: 100%;
			}
		}
	}

	/** IPHONE 8 */
	@media only screen and (device-width: 375px) {
		height: 110px;

		.icon {
			margin: 0px auto;
			height: 38px;

			svg {
				width: 38px;
				height: 38px;
			}
		}

		.value-passport {
			top: -12px;
		}
	}
}
