.onboarding-incomes {
	margin: auto;
	.header-onboarding {
		font-family: "BalooDa2Bold";
	}

	/** MOBILES **/
	@media screen and (max-width: 480px) {
		margin: 10px auto;
	}
}
