.onboarding-funding-passport-input-card {
	background: white;
	border-radius: 20px;
	padding: 0px 20px 0px 0px;
	width: 100%;
	height: 130px;
	margin: 15px 0px 0px 0px;

	.headers-label {
		// position: relative;
		margin-left: 50px;

		.label {
			color: var(--grey);
			// width: fit-content;
			border-radius: 20px;
			padding: 0px 6px;
			height: 19px;

			span {
				font-size: 0.813rem;
				font-family: "BalooDa2SemiBold";
				position: relative;
				top: -4px;
			}
		}
	}

	.value {
		width: 150px;
		margin: 5px auto 0px;
		border-bottom: 1px solid var(--grey);
		line-height: 36px;
		

		input,
		span {
			line-height: 36px;
		}
		.units{
			font-family: "BalooDa2SemiBold";
			font-size: 1.25rem;
		}

		input {
			color: var(--fridaa-blue);
			font-size: 1.5rem;
			font-family: "BalooDa2Bold";
			width: 100%;
			height: auto;
			text-align: left;
			padding: 0px;
		}

		
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		.value {
			width: 80%;

			input {
				font-size: 1.75rem;
			}

		}
	}

	/** MOBILES **/
	@media screen and (max-width: 480px) {
		.value {
			width: 80%;
			
			input {
				width: 70%;;
			}
		}
	}

	/** IPHONE 8 */
	@media only screen and (device-width: 375px) {
		height: 110px;

		.icon {
			margin: 0px auto;
			height: 38px;

			svg {
				width: 38px;
				height: 38px;
			}
		}

		.value {
			top: -12px;
		}
	}
}
