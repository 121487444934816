.height-small-input {
	height: 200px;
}
.height-large-input {
	height: 300px;
}
.onboarding-input-card-property-type {
	background: white;
	border-radius: 20px;
	padding: 20px;
	width: 80%;
	// height: 230px;
	margin: 15px auto 10px;

	

	.input-container {
		width: 80%;
		margin-top: 30px;
		margin: 30px auto 0px;
		span {
			color: var(--grey);
			font-family: "BalooDa2Bold";
			font-size: 0.5rem;

			&:first-child {
				font-size: 1.1rem;
				margin-right: 2px;
			}
		}
	}
	.icon {
		margin: 5px auto 0px;
		height: 41px;

		svg {
			width: 114px;
			height: 41px;
			margin: 0px auto;
		}
	}

	.value {
		width: 100%;
		margin: -5px auto 0px;
		border-bottom: 1px solid var(--grey);
		line-height: 36px;

		input,
		span {
			line-height: 36px;
		}

		input {
			color: var(--fridaa-blue);
			font-size: 1.5rem;
			font-family: "BalooDa2Bold";
			width: 100%;
			height: auto;
			text-align: left;
			padding: 0px;
		}

		.units {
			margin: 6px 0px auto auto;
			// padding-left: 40px;
			
			span {
				color: var(--grey);
				font-family: "BalooDa2Bold";
				font-size: 0.5rem;

				&:first-child {
					font-size: 1.1rem;
					margin-right: 2px;
				}
			}
		}
	}
	.isClickedError {
		width: 100%;
		margin: -5px auto 0px;
		border-bottom: 1px solid var(--red);
		line-height: 36px;

		input,
		span {
			line-height: 36px;
		}

		input {
			color: var(--fridaa-blue);
			font-size: 1.5rem;
			font-family: "BalooDa2Bold";
			width: 110px;
			height: auto;
			text-align: left;
			padding: 0px;
		}

		.units {
			margin: 6px 0px auto auto;
			// padding-left: 40px;
			
			span {
				color: var(--grey);
				font-family: "BalooDa2Bold";
				font-size: 0.5rem;

				&:first-child {
					font-size: 1.1rem;
					margin-right: 2px;
				}
			}
		}
	}

	/** DESKTOP **/
	@media screen and (min-width: 769px) {
		width: 500px;
		
		
		.value {
			width: 100%;

			input {
				font-size: 1.75rem;
			}

			.units {
				span {
					&:first-child {
						font-size: 1.40rem;
					}
				}
			}
		}
		.isClickedError {
			width: 100%;

			input {
				font-size: 1.75rem;
			}

			.units {
				span {
					&:first-child {
						font-size: 1.40rem;
					}
				}
			}
		}
	}

	/** MOBILES **/
	@media screen and (max-width: 480px) {
		
		.value {
			width: 100%;
			
			input {
				width: 90px;
			}
		}
		.isClickedError {
			width: 100%;
			
			input {
				width: 90px;
			}
		}
	}

	/** IPHONE 8 */
	@media only screen and (device-width: 375px) {
		// height: fit-content;
		// margin-bottom: 20px;

		.icon {
			margin: 0px auto;
			height: 38px;

			svg {
				width: 38px;
				height: 38px;
			}
		}

		.value {
			top: -12px;
		}
	}
}
